.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.parentDiv {
  background-color: #202020;
  border-radius: 30px;
  width: 585px;
  height: 141px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s; /* Smooth transition for background color */
}

.parentDiv:hover {
  background-color: #ffffff;
}

.parentDiv:hover .defaultImage {
  display: none; /* Hide default image on hover */
}

.parentDiv:hover .hoverImage {
  display: inline-block; /* Display hover image on hover */
}

.defaultImage,
.hoverImage {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

.hoverImage {
  display: none; /* Initially hide hover image */
}


 
