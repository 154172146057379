@keyframes slideIn {
    from {
      transform: translateY(10%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .slide-in {
    animation: slideIn 0.8s ease-in-out forwards;
  }
  

  