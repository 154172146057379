@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100vw;
  /* 100% of the viewport width */
  min-width: 100%;
  /* Ensure a minimum width of 100% */
  overflow-x: hidden;
  /* Hide horizontal overflow */
  overflow-y: auto;
  /* Allow vertical overflow if needed */
  margin: 0;
  /* Remove default margin */
  padding: 0;
  /* Remove default padding */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.element-with-overflow {
  overflow-y: auto; /* Make the element vertically scrollable */
  max-height: 80vh; /* Set a maximum height to prevent excessive scrolling */
}


/* Lnading Page css */
.gradient-makro {
  background: linear-gradient(180deg, #A7A7A7 0%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}







.gradient-state {
  background: linear-gradient(180deg, #1C9B91 -55.24%, rgba(16, 143, 133, 0) 218.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


.gradient-voice {
  background: linear-gradient(180deg, #62B5B0 0%, #21706C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}



.gradient-client {
  background: linear-gradient(180.17deg, #208DC3 -13.18%, rgba(32, 141, 195, 0) 95.81%);
  border-radius: 0px 353px 0px 0px;
}




.slick-dots {
  position: relative;
  bottom: -50px;
  /* Adjust the position */
}


.nav {
  background: rgba(255, 255, 255, 0.47);
  box-shadow: 0px 13px 85.3px rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(20.4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 72px 72px;
}

.nav-mobile {
  background: rgba(255, 255, 255, 0.47);
  box-shadow: 0px 13px 85.3px rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(20.4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 32px 32px;
}

.nav-mobile1 {
  background: #121212;
  opacity: 84%;
  box-shadow: 0px 13px 85.3px rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(20.4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 32px 32px;
}


.nav-menu {
  background: rgba(255, 255, 255, 0.47);
  box-shadow: 0px 13px 85.3px rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(20.4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 32px 32px 32px 32px;
}



.btn-schedule {
  width: 274px;
  height: 69px;
  left: 111px;
  top: 962px;

  background: rgba(255, 255, 255, 0.51);
  backdrop-filter: blur(2.907px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 28.1422px;
}



.gradient-certificate {
  background: linear-gradient(180deg, #2D74F1 49.41%, #5F32A2 87.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}



.gradient-stanial {
  background: linear-gradient(180deg, #62B5B0 0%, #21706C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}




.gradient-dedicated {
  background: linear-gradient(180deg, #1C9B91 -55.24%, rgba(16, 143, 133, 0) 218.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


.gradient-navbar {

  background: rgba(255, 255, 255, 0.47);
  box-shadow: 0px 13px 85.3px rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(20.4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 42px 42px;
}


.gradient-login {

  background: linear-gradient(90.2deg, #9C7615 -14.46%, #E6B129 99.82%);
  border-radius: 12px;

}

.gradient-founder {

  background: linear-gradient(159.69deg, #FFFFFF -28.69%, rgba(255, 255, 255, 0.53) 120.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

}

.gradient-ceo {

  background: linear-gradient(151.32deg, #FFFFFF -2.94%, rgba(255, 255, 255, 0) 122.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

}

.gradient-makro {
  background: linear-gradient(180deg, #A7A7A7 0%, rgba(0, 0, 0, 0) 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

}


.design1 {

  background: linear-gradient(-272.67deg, #EAE7F8 -42.06%, #AA9CAB 126.66%);

}

.design2 {

  background: linear-gradient(-93.31deg, #832C32 -61.13%, #C56E7E 113.14%);

}


.design3 {


  background: linear-gradient(-93.34deg, #263558 -18.46%, #C67999 113.18%, #9D5D1C 113.18%);

}


.design4 {

background: linear-gradient(-95.58deg, #9F92A3 -19.95%, #76697B 117.38%);
}

.design5 {
  background: linear-gradient(-92.63deg, #D7BD96 -30.77%, #D3A188 111.99%);
}


.design6 {
  background: linear-gradient(-93.98deg, #9B8A83 -17.44%, #66462C 147.84%);
}



.design7 {
  /* background: linear-gradient(-93.98deg, #9B8A83 -17.44%, #66462C 147.84%); */
 


background: linear-gradient(-93.98deg, #9B8A83 -17.44%, #66462C 147.84%);


}


.design8 {


  background: linear-gradient(272.67deg, #BB9F88 -42.06%, #DAC9C1 126.66%);

}

.design9 {


  /* background: linear-gradient(272.67deg, #D0BCC7 -42.06%, #C397A6 126.66%); */

background: linear-gradient(-272.67deg, #DCD1D6 -42.06%, #8A6F5C 126.66%);
/* transform: rotate(180deg); */



}


.design10 {
  background: linear-gradient(-272.67deg, #F3DEC8 -42.06%, #936D47 126.66%);
}

.design11 {
  background: linear-gradient(-272.67deg, #DCD1CF -42.06%, #67432C 126.66%);
  /* Rectangle 36 */

/* background: linear-gradient(-93.19deg, #964B50 -48.73%, #BA7E86 112.92%); */



}

.design12 {
  background: linear-gradient(-272.67deg, #B6896A -42.06%, #622E05 126.66%);
}

.design13 {


background: linear-gradient(-272.67deg, #D3ACC7 -42.06%, #C07A9C 126.66%);


}


.design14 {

  background: linear-gradient(-272.67deg, #B9ADB0 -42.06%, #9F9193 126.66%);
}


.design15 {

  background: linear-gradient(-272.67deg, #5B8B8B -42.06%, #235A57 126.66%);

  /* background: linear-gradient(-93.98deg, #ADA9C7 -17.44%, #5768A3 147.84%); */
}

.design16 {


background: linear-gradient(272.67deg, #941C4B -42.06%, #B44F88 22.94%, #87898E 72.67%, #7A5CB2 126.66%);



}

.design17 {


  background: linear-gradient(272.67deg, #EBDDD7 -42.06%, #8A7463 126.66%);


}

.gradient-landing {


  background: linear-gradient(180deg, rgba(19, 19, 19, 0) 0%, #000000 100%);


}

.gradient-fabric {




  background: linear-gradient(180deg, #2AF085 0%, rgba(42, 240, 133, 0) 123.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;



}



.gradient-apprealmb {


  background: linear-gradient(180deg, #9747FF 0%, rgba(151, 71, 255, 0.65) 100%);

}


.gradient-inside {


  background: linear-gradient(180deg, #EDB932 0%, #FFD25E 100%);
  border-radius: 42.0698px;

}


.gradient-form
{

  background: linear-gradient(-180deg, #9747FF 0%, #0A6FC0 130.62%);

}


.gradient-button
{

  background: linear-gradient(-90deg, #E3A90E 0%, #7D5008 100%);
  border-radius: 10.2132px;
}


.active input[type="radio"] {
  box-shadow: 0 0 10px rgba(45, 17, 82, 0.5), 0 0 20px rgba(91, 43, 153, 0.5);
}

.active {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
}





.slide-in {
  transform: translateY(0);
  opacity: 1;
}

.slide-out {
  transform: translateY(100%);
  opacity: 0;
}



.ises {
  border-bottom: 2px solid #000000;
  /* Black color for initial state */
  transition: border-color 0.3s, border-width 0.3s;
  /* Add transition for border color and width */
  width: auto;
  /* Set initial width */
}

.ises:hover {
  border-bottom: 2px solid #FFFFFF;
  /* Change to white color on hover */
  width: 120%;
  /* Expand the divider width slightly on hover */
}

.premises .ises {
  border-bottom: 2px solid #000000;
  /* Black color for initial state */
  transition: border-color 0.3s, width 0.3s;
  /* Add transition for border color and width */
  width: auto;
  /* Set initial width */
}

.premises:hover .ises {
  border-bottom: 2px solid #FFFFFF;
  /* Change divider color to white on hover */
  width: 120%;
  /* Expand the divider width slightly on hover */
}

.premises:hover .text-black {
  color: #FFFFFF;
  /* Change text color to white on hover */
}


.indicator-container {
  position: absolute;
  bottom: 10px;
  /* Adjust as needed */
}

.indicator {
  width: 10px;
  height: 4px;
  /* Adjust height as needed */
  background-color: #ccc;
  border-radius: 2px;
  /* Adjust border-radius to make the rectangle more rounded */
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background-color: #FFA500;
  /* Orange color */
}

.container:hover .img1 {
  filter: grayscale(100%) hue-rotate(180deg);
}


.container .img1,
.container .img2 {
  transition: transform 1100ms;
  /* Transition transform property over 1100 milliseconds */
}

.container:hover .img1,
.container:hover .img2 {
  filter: brightness(1);
  /* Adjust brightness value as needed */
}

.container:hover .img1 {
  transform: translateY(-29px);
}

.container:hover .img2 {
  transform: translateY(29px);
}



.container:hover .img3 {
  transform: translateY(-29px);
}

.container1:hover .txt1 {
  transform: translateY(-40px);
}



.zoom-effect {
  transition: all 2s ease;
}

.zoom-effect:hover {
  width: 900px;
  height: 700px;
}


/* Zoom Out #1 */
.hover03 figure img {
  -webkit-transform: scale(1.5);
  transform: scale(1.2);
  -webkit-transition: .3s ease-in-out;
  transition: .7s ease-in-out;
}

.hover03 figure:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.container {
  position: relative;
  overflow: hidden;
}

.container:hover .txt {
  transform: translateY(-10px);
  /* Adjust the value as needed */
}



h1,
p {
  font-family: Lato;
}



.slider-container {}

.slider-list {
  display: inline-flex;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-right: calc(100% - 400px);
  /* fix width = 400 */
}

.slider-item {
  /* background-color: #d3e9c5; */
  width: 87%;
  /* fix width = 400 */
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 18px;
  /* border-left: 2px solid salmon;
  border-bottom: 2px solid salmon; */
}

.slider-item:not(:last-of-type) {
  margin-right: 12px;
  /* space */
}


.address-container {
  display: flex;
  align-items: flex-start; /* Align items at the start of the container */
}

.address-text {
  margin-left: 1px; /* Adjust margin as needed */
}




.slide-transition {
  transition: transform 0.5s ease; /* Adjust the transition duration as needed */
}









@media (max-width: 375px) {
  .slider-wrapper {
    width: 143% !important;
    
  }

  .nav-mobile1 {
    background: #121212;
    opacity: 84%;
    width: 89%;
   
}
  
}



/* @ media-large */

@media (max-width: 1000px) {
  .slider-container {
    padding-right: 20vh !important;
    /* dynamic width = 80vh */
  }

  .slider-item {
    width: 33vw;
    margin-top: 12px;
    /* dynamic width = 80vh */
  }
}









@keyframes slide-in-out {

  0%,
  100% {
    left: 80%;
    /* Start and end position */
    opacity: 0;
    /* Ensure opacity when content is at start and end position */
  }

  50% {
    left: 100%;
    /* Midpoint position */
    opacity: 1;
    /* Hide the content at the midpoint */
  }
}








@media only screen and (max-width: 425px) {
  .btn-schedule {
    width: 154px;
    height: 39px;
    left: 111px;
    top: 962px;

    background: rgba(255, 255, 255, 0.51);
    backdrop-filter: blur(2.907px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 28.1422px;
  }

  .gradientgradient-voice {
    background: linear-gradient(180deg, #62B5B0 0%, #21706C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

  }

  .gradient-testimonials {
    background: linear-gradient(159.69deg, #FFFFFF -28.69%, rgba(255, 255, 255, 0.53) 120.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

  }

  .gradient-certificate {
    background: linear-gradient(180deg, #2D74F1 33.98%, #5F32A2 110.16%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

  }

  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1eed5fa-MuiInputBase-root-MuiInput-root {
    /* border: 2px solid red; */
    border-bottom: 1px solid white;
  }

}


.arrow-animation {
  animation: oscillate 0.7s ease-in-out infinite alternate;
}

@keyframes oscillate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px); /* Adjust this value to control the extent of oscillation */
  }
}